<template>
  <b-overlay :show="busy" rounded="sm">
    <ValidationObserver ref="form">
      <form @submit.prevent="handleSubmit" id="frmQuiz">
        <b-card class="editquiz">
          <template v-slot:header>
            {{ isUpdateQuizRequest ? 'Update Quiz' : 'Add New Quiz' }}
          </template>
          <b-card-body class="py-0">
            <p>
              <small>All fields with <span class="text-danger">*</span> are required.</small>
            </p>
            <div class="form-group row">
              <div class="col">
                <label>Question:</label><span class="ml-1 text-danger">*</span>
                <ValidationProvider name="Question" rules="required" v-slot="{ errors }">
                  <RichTextEditor v-model="quiz.question" />
                  <span class="text-danger form-text">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-3">
                <label>Options:</label>
                <b-form-checkbox class="mt-3" v-model="quiz.allow_multiple_answers" @change="resetChecks">
                  <small>There are multiple correct answers</small>
                </b-form-checkbox>
              </div>
              <div class="col-sm-9">
                <ValidationProvider ref="answersCheck" name="Correct Answer" :rules="`required${(quiz.allow_multiple_answers ? '|minLength:2' : '')}`" v-slot="answersCheck">
                  <b-form-group>
                    <b-list-group>
                      <b-list-group-item v-for="(v, i) in optionsCount" :key="i">
                        <div>
                          <span class="mr-3">Option {{v}}:<span class="ml-1 text-danger">*</span></span>
                          <b-form-checkbox v-model="quiz.answers" :value="i" name="Correct Answer" inline v-if="quiz.allow_multiple_answers">
                            <small>This is a Correct Answer</small>
                          </b-form-checkbox>
                          <b-form-radio v-model="selectedRadio" :value="i" name="Correct Answer" inline v-else>
                            <small>This is the Correct Answer</small>
                          </b-form-radio>
                          <button class="btn btn-sm text-muted float-right mr-0 pr-0" type="button" title="Delete option" @click="removeOption(i)" v-if="(optionsCount > minOptionsCnt)">
                            <DeleteIcon />
                          </button>
                        </div>
                        <div>
                          <ValidationProvider :name="`Option ${v}`" rules="required" v-slot="{ errors }">
                            <textarea class="form-control" v-model="quiz.options[i]" :name="`quiz.options.${i}`" rows="1" />
                            <span class="text-danger form-text">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </div>
                      </b-list-group-item>
                      <b-list-group-item v-if="(maxOptionsCnt > optionsCount)">
                        <div>
                          <button type="button" class="btn btn-sm btn-outline-secondary" title="Add a new option" @click="appendOption"><AddIcon /> Add an Option</button>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </b-form-group>
                  <span class="text-danger form-text">{{ answersCheck.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-6">
                <label>Point:</label><span class="ml-1 text-danger">*</span>
                <ValidationProvider name="Point" :rules="`between:${minPoint},${maxPoint}|required`" v-slot="{ errors }">
                  <input v-model="quiz.point" name="quiz.point" class="form-control" type="number" :min="minPoint" :max="maxPoint" />
                  <span class="text-danger form-text">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-sm-6">
                <label>Author of this Quiz:</label><span class="ml-1 text-danger">*</span>
                <ValidationProvider name="Author" rules="required" v-slot="{ errors }">
                  <input v-model="quiz.author" name="quiz.author" class="form-control" type="text" />
                  <span class="text-danger form-text">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label>Explanation of correct answer(s):</label>
                <textarea v-model="quiz.explanation" name="quiz.explanation" class="form-control" />
              </div>
            </div>

          </b-card-body>
          <template v-slot:footer>
            <button class="btn btn-primary" type="submit" :disabled="submitting">
              <b-spinner small v-if="submitting" />
              {{ isUpdateQuizRequest ? 'Update Quiz' : 'Add Quiz' }}
            </button>
            <button class="btn btn-danger" type="reset" :disabled="submitting" @click.prevent="reset">Reset</button>
          </template>
        </b-card>
      </form>
    </ValidationObserver>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import DeleteIcon from 'mdi-vue/TrashCanOutline';
import AddIcon from 'mdi-vue/Plus';
import RichTextEditor from '@/components/ui/RichTextEditor.vue';
import '@/util/validation';
import settings from '@/app.config';

// custom minLength validation rule
extend('minLength', {
  params: ['length'],
  validate(value, { length }) {
    return value.length >= length;
  },
  message: 'At least {length} options must be selected.',
});

export default {
  name: 'EditQuizForm',
  components: {
    ValidationProvider, ValidationObserver, DeleteIcon, AddIcon, RichTextEditor,
  },
  props: {
    book_id: { type: String, required: true },
    value: {
      type: Object,
      default: null,
    },
    busy: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      quiz: {},
      submitting: false,
      minPoint: settings.point.min,
      maxPoint: settings.point.max,
      minOptionsCnt: 2,
      maxOptionsCnt: settings.maxQuizOptionsCount,
      selectedRadio: '',
    };
  },

  computed: {
    isUpdateQuizRequest() {
      return this.value && this.value.id;
    },
    optionsCount() {
      return (this.quiz.options && this.quiz.options.length) || 0;
    },
  },

  methods: {
    handleSubmit() {
      this.$refs.form.validate().then((success) => {
        if (!success) return this.$toast.error('Please check your input', 'Form Not Valid');

        const newQuiz = { ...this.quiz };
        newQuiz.book_id = this.book_id;
        newQuiz.point = +newQuiz.point;
        if (this.quiz.allow_multiple_answers) newQuiz.allow_multiple_answers = true;
        else {
          newQuiz.allow_multiple_answers = false;
          newQuiz.answers = [this.selectedRadio];
        }
        // call the right handler based on incoming request
        if (this.isUpdateQuizRequest) this.updateQuiz(this.book_id, newQuiz);
        else this.addQuiz(this.book_id, newQuiz);

        return false;
      });
    },
    addQuiz(bookId, newQuiz) {
      this.submitting = true;
      this.$store.dispatch('quiz/addQuizzes', { bookId, quizzes: [newQuiz] }).then(() => {
        const msg = 'Quiz Added Successfully';
        this.$toast.success(msg);
        this.reset();
      }).catch(() => {
        this.$toast.error('Failed to add Quiz. Try again later.');
      }).finally(() => {
        this.submitting = false;
      });
    },
    updateQuiz(bookId, updQuiz) {
      this.submitting = true;
      this.$store.dispatch('quiz/updateQuizzes', { bookId, quizzes: [updQuiz] }).then(() => {
        const msg = 'Quiz Updated Successfully';
        this.$toast.success(msg);
        this.$emit('input', updQuiz);
      }).catch(() => {
        this.$toast.error('Failed to add Quiz. Try again later.');
      }).finally(() => {
        this.submitting = false;
      });
    },
    appendOption() {
      if (this.quiz.options && this.quiz.options.length) this.quiz.options.push('');
    },
    removeOption(idx) {
      const deleteCount = 1; // only 1 option will be deleted when the trash icon is clicked
      // delete the item from the options array
      if (this.quiz.options && (idx < this.quiz.options.length)) this.quiz.options.splice(idx, deleteCount);
      // delete it from the answers if there
      if (this.quiz.answers && this.quiz.answers.length) this.quiz.answers = this.quiz.answers.filter(v => v !== idx);
      // delete it also from the radio button answers if checked
      const radioIdx = +(this.selectedRadio);
      if (radioIdx === idx) this.selectedRadio = '';
      if (radioIdx > idx) this.selectedRadio = (radioIdx - deleteCount).toString();
    },
    resetChecks() {
      this.$set(this.quiz, 'answers', []);
      this.selectedRadio = '';
      this.$refs.answersCheck.reset();
    },
    resetOptions() {
      if (this.isUpdateQuizRequest) {
        this.selectedRadio = (this.value && this.value.answers) ? this.value.answers[0] : '';
      } else {
        const options = new Array(this.minOptionsCnt);
        options.fill('');
        this.$set(this.quiz, 'options', options);
        this.$set(this.quiz, 'answers', []);
        this.selectedRadio = '';
      }
    },
    reset() {
      this.$refs.form.reset();
      this.quiz = Object.assign({}, this.value);
      this.resetOptions();
    },
  },

  mounted() {
    this.reset();
  },
};
</script>

<style lang="stylus" scoped>
.editquiz
  .form-group
    margin-bottom 0.5rem
    label
      margin-bottom 0
  .btn
    margin-right 1rem

</style>
